@import '~@angular/material/prebuilt-themes/indigo-pink.css';

/* You can add global styles to this file, and also import other style files */
:root {
    --service-tag-color: rgb(155, 48, 48);
}

/* Element selectors */
html,body {
    /* min-width: 100%;
    min-height: 100%;
    margin: 0px;
    padding: 0px;
    overflow-x: hidden;  */
    height: 100%;
  padding: 0;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;

  main {
    position: relative;
    z-index: 1;

    .platform-main {
      width: 100%;
    }

    .ontario-paragraph {
      font-family: "Raleway", "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    }
  }
}
.ontario-row .ontario-row.full-width {
  max-width: none !important;
  /* width: 100%; */
}


.container-full-width{
  padding: 1rem;
  width: 100%;
}


/* Fonts */
@font-face {
    font-family: "Gotham";
    src: url("assets/fonts/gothambook.woff") format("woff");
}

@font-face {
    font-family: "Gotham Medium";
    src: url("assets/fonts/gothammedium.woff") format("woff");
}

@font-face {
    font-family: "Gotham Bold";
    src: url("assets/fonts/gothambold.woff") format("woff");
}